<!-- eslint-disable vue/html-indent -->
<template>
  <v-img
    style="background-color: #234e79; background-image: linear-gradient(110deg, #234e79 0%, #9895e2 100%);"
  >
    <base-section
      id="pricing"
    >
      <v-responsive
        class="mx-auto"
        max-width="1650"
      >
        <v-container fluid>
          <v-card
            flat
            color="transparent"
          >
            <div class="text-center white--text pt-4">
              <h2>
                Choose the Lavvira plan that's right for you
              </h2>
            </div>
            <v-row
              justify="center"
              class="mt-16"
            >
              <v-col
                cols="12"
                md="2"
                lg="3"
              >
                <v-card
                  class="mb-6 mx-auto"
                  min-height="500"
                  max-width="300"
                  elevation="8"
                >
                  <v-card-title
                    class="justify-center"
                  >
                    <h3
                    style="color: #234e79;"
                    >
                      Single
                    </h3>
                  </v-card-title>
                  <v-card-text
                    class="text-center pt-16"
                  >
                    <v-btn
                      color="primary"
                      href="https://app.lavvira.net/register"
                    >
                      Start 30 days free trial
                    </v-btn>
                    <!-- <div class="mt-6">
                      <h4>after that 90€/month</h4>
                      <span>Billed montly</span>
                    </div> -->
                  </v-card-text>
                  <div
                    class="single-pricing-element"
                  >
                    <a
                      @click="scrollToLavviraFeaturesSection()"
                    >
                      <img
                        :src="require('@/assets/Lavvira_monogramm_logo.svg')"
                        class="mr-2 aligned-img"
                        style="max-width: 27px;"
                      >
                      All core features
                    </a>
                    <div>
                      <img
                        :src="require('@/assets/Lavvira_monogramm_logo.svg')"
                        class="mr-2 aligned-img"
                        style="max-width: 27px;"
                      >
                      5 GB of storage
                    </div>
                    <div>
                      <img
                        :src="require('@/assets/Lavvira_monogramm_logo.svg')"
                        class="mr-2 aligned-img"
                        style="max-width: 27px;"
                      >
                      Email support
                    </div>
                  </div>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                md="2"
                lg="3"
              >
                <v-card
                  class="mb-6 mx-auto"
                  min-height="500"
                  max-width="300"
                  elevation="8"
                >
                  <v-card-title
                    class="justify-center"
                  >
                    <h3 style="color: #234e79;">
                      Company
                    </h3>
                  </v-card-title>
                  <v-card-text
                    class="text-center pt-16"
                  >
                    <v-btn
                      color="primary"
                      min-width="220"
                      href="https://app.lavvira.net/register"
                    >
                      Start now
                    </v-btn>
                  </v-card-text>
                  <div class="single-pricing-element">
                    <a
                      @click="scrollToLavviraFeaturesSection()"
                    >
                      <img
                        :src="require('@/assets/Lavvira_monogramm_logo.svg')"
                        class="mr-2 aligned-img"
                        style="max-width: 27px;"
                      >
                      <span>
                        All core features
                      </span>
                    </a><br>
                    <a
                      @click="scrollToLavviraFeaturesSection()"
                    >
                      <img
                        :src="require('@/assets/Lavvira_monogramm_logo.svg')"
                        class="mr-2 aligned-img"
                        style="max-width: 27px;"
                      >
                      All advanced features
                    </a>
                    <div>
                      <img
                        :src="require('@/assets/Lavvira_monogramm_logo.svg')"
                        class="mr-2 aligned-img"
                        style="max-width: 27px;"
                      >
                      150 GB of storage
                    </div>
                    <div>
                      <img
                        :src="require('@/assets/Lavvira_monogramm_logo.svg')"
                        class="mr-2 aligned-img"
                        style="max-width: 27px;"
                      >
                      Premium support
                    </div>
                  </div>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                md="2"
                lg="3"
              >
                <v-card
                  class="mb-6 mx-auto"
                  min-height="500"
                  max-width="300"
                  elevation="8"
                >
                <v-card-title
                    class="justify-center"
                  >
                    <h3 style="color: #234e79;">
                      Custom
                    </h3>
                  </v-card-title>
                  <v-card-text
                    class="text-center pt-16"
                  >
                    <v-btn
                      color="primary"
                      min-width="220"
                      @click="goToCalendar()"
                    >
                      Contact us
                    </v-btn>
                    <!-- <div class="mt-6">
                      <h4>Custom</h4>
                    </div> -->
                  </v-card-text>
                  <div class="single-pricing-element">
                    <a
                      @click="scrollToLavviraFeaturesSection()"
                    >
                      <img
                        :src="require('@/assets/Lavvira_monogramm_logo.svg')"
                        class="mr-2 aligned-img"
                        style="max-width: 27px;"
                      >
                      All core features
                    </a><br>
                    <a
                      @click="scrollToLavviraFeaturesSection()"
                    >
                      <img
                        :src="require('@/assets/Lavvira_monogramm_logo.svg')"
                        class="mr-2 aligned-img"
                        style="max-width: 27px;"
                      >
                      All advanced features
                    </a>
                    <div>
                      <img
                        :src="require('@/assets/Lavvira_monogramm_logo.svg')"
                        class="mr-2 aligned-img"
                        style="max-width: 27px;"
                      >
                      150 GB of storage
                    </div>
                    <div>
                      <img
                        :src="require('@/assets/Lavvira_monogramm_logo.svg')"
                        class="mr-2 aligned-img"
                        style="max-width: 27px;"
                      >
                      Premium support
                    </div>
                    <div>
                      <img
                        :src="require('@/assets/Lavvira_monogramm_logo.svg')"
                        class="mr-2 aligned-img"
                        style="max-width: 27px;"
                      >
                      RESTful API
                    </div>
                    <div>
                      <img
                        :src="require('@/assets/Lavvira_monogramm_logo.svg')"
                        class="mr-2 aligned-img"
                        style="max-width: 27px;"
                      >
                      Custom integration
                    </div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-responsive>
    </base-section>
  </v-img>
</template>

  <script>

  export default {
    methods: {
      scrollToLavviraFeaturesSection () {
        const element = document.getElementById('lavvira-features')
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
      },
      goToCalendar () {
        const element = document.getElementById('lavvira-demo-and-contact')
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
      },
    },
  }
  </script>

  <style scoped>
  .divider-position {
    position: absolute;
    width: 90%;
    bottom: 90px;
  }
  .element-position {
    position: absolute;
    width: 90%;
    bottom: 25px;
  }
  .divider-position-company {
    position: absolute;
    width: 90%;
    bottom: 110px;
  }
  .single-pricing-element {
    font-size: 19px;
    line-height: 2;
    margin-left: 15%;
  }
  .aligned-img {
    vertical-align: middle;
  }
  </style>
